<template>
    <div class="bread" >
        <div class="d-flex" v-if="name == 'search'">
            <router-link class="grey" to="/">首页</router-link>
            <i>></i>
            <span class="black">{{val}}</span>
        </div>
        <div class="d-flex" v-else-if="name == 'product'">
            <div v-if="two">
                <router-link class="grey" to="/">首页</router-link>
                <i>></i>
                <span class="grey" @click="to_product(1)">{{one}}</span>
                <i>></i>
                <span class="black">{{two}}</span>
            </div>
            <div v-else>
                <router-link class="grey" to="/">首页</router-link>
                <i>></i>
                <span class="black">{{one}}</span>
            </div>

            
        </div>
        <div class="d-flex" v-else-if="name == 'detail'">
            <router-link class="grey" to="/">首页</router-link>
            <i>></i>
            <span class="grey" @click="to_product(1)">{{one}}</span>
            <i>></i>
            <span class="grey" @click="to_product(2)">{{two}}</span>
            <i>></i>
            <span class="black">{{good}}</span>
        </div>

    </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";


export default defineComponent({
    name:'bread',
    emits:['get'],
    props:{
        active: String,
        text:String,
        first:String,
        second: String,
        first_id:String,
        second_id: String,
        pro: String
    },
    setup(props,ctx) {
        let name = reactive(props.active)
        let val = reactive(props.text)
        let one = reactive(props.first)
        let two = reactive(props.second)
        let one_id = reactive(props.first_id)
        let two_id = reactive(props.second_id)
        let good = reactive(props.pro)
        const router = useRouter();


        let to_product = (num) => {
            let d = {}
            if(num == 1) {
              d = { first:one, first_id: one_id }
            }else {
              d = { first:one, first_id: one_id,second:two, second_id: two_id }
            }

            
            
            router.push({
                path: '/product',
                query:d
            }) 
            
            if(name == 'product') {
                ctx.emit('get',d);
            }

            window.scrollTo(100,0)

        }

        return {
            name,
            val,
            one,
            one_id,
            two,
            two_id,
            good,
            to_product
        };
    },
});
</script>

<style lang="less" scoped>
.bread {
    width: 100%;
    height: 60px;
    padding: 20px 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    font-size: 14px;
    margin-bottom: 20px;
    .grey,i {
        color: #999;
        cursor: pointer;
    }
    .black {
        color: #333;
    }
    i {
        margin: 0 5px;
    }
}

</style>
